<template>
  <CommonLoading v-if="loading" class="mx-auto" />
  <form
    v-else
    class="ml-auto mr-auto grid gap-4"
    :class="inPaywall ? 'w-full' : 'w-64'"
    @submit.prevent="submit"
    id="form_user_login"
  >
    <FormAlert v-if="alert" :type="alert.type">{{ alert.message }}</FormAlert>

    <FormAlert v-if="ssoUrl" type="info">
      <div class="flex flex-col gap-y-4">
        <span
          class="prose text-sm leading-tight prose-a:text-blue prose-a:underline prose-a:hover:text-denimblue prose-a:hover:no-underline"
        >
          {{ $t('sso.yourEmailIsOnAnSSOClient') }}
        </span>
        <ul>
          <li>
            <NuxtLink external :to="ssoUrl" class="underline text-blue">
              {{ $t('sso.loginHereInstead') }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </FormAlert>
    <FormAlert v-if="!isPasswordSet" type="info">
      <span
        class="prose text-sm leading-tight prose-a:text-blue prose-a:underline prose-a:hover:text-denimblue prose-a:hover:no-underline"
      >
        {{ $t('PasswordHasNotBeenSet') }}
        <span
          class="text-sm font-sans font-medium text-blue underline cursor-pointer hover:no-underline"
          @click="showForgotPassword"
        >
          {{ $t('ClickToGetALinkSent') }}
        </span>
      </span>
    </FormAlert>

    <FormInput
      ref="emailinput"
      :modelValue="email"
      type="email"
      required
      placeholder="Email"
      @blur="checkEmail"
      @update:modelValue="$emit('update:email', $event)"
    />

    <FormPassword
      :modelValue="password"
      placeholder="Password"
      :pattern="false"
      :minlength="false"
      @update:modelValue="$emit('update:password', $event)"
    />

    <input
      data-testid="popup-login-button"
      type="submit"
      class="cursor-pointer py-3 px-4 text-white article-label !font-medium w-full rounded-full"
      :value="$t('Login')"
      :class="
        false
          ? 'bg-black transform translate-y-px'
          : 'bg-blue hover:bg-denimblue'
      "
    />
    <a
      v-if="(false || false) && allowWayf"
      :href="wayfUrl"
    >
      <img class="mx-auto py-2 px-4" src="/wayf-logo.png" alt="WAYF login" />
    </a>
    <slot />
  </form>
</template>

<script setup lang="ts">
import type { AlertBundle } from '~/typesManual/alert'

import { SSOProvider } from '~/typesManual/sso'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const dataLayer = useDataLayer()

const userStore = useUserStore()

const emit = defineEmits<{
  (e: 'update:password', value: string): void
  (e: 'update:email', value: string): void
  (e: 'showForgotPassword'): void
}>()

const showForgotPassword = () => {
  emit('showForgotPassword')
}
const isPasswordSet = ref(true)
const loading = ref(false)
const emailinput = ref<HTMLInputElement>()

const props = withDefaults(
  defineProps<{
    email: string
    password: string
    allowWayf?: boolean
    inPaywall?: boolean
  }>(),
  { allowWayf: true }
)

const alert = ref<AlertBundle | undefined>(undefined)

const submit = async () => {
  loading.value = true
  alert.value = undefined

  const ssoProviderInfo = await nuxtApp.$api.user.getSsoProviderByEmail(
    props.email
  )
  if (!ssoProviderInfo) {
    const result = await userStore.loginUser(props.email, props.password)

    if (result.status === 'error') {
      emit('update:password', '')
      if (result.code === 401) {
        alert.value = {
          type: ALERT_TYPE.ERROR,
          message: nuxtApp.$t('Wronglogin'),
        }
      } else if (result.code === 500) {
        alert.value = {
          type: ALERT_TYPE.ERROR,
          message: nuxtApp.$t('ServerError'),
        }
        const capturedError = new Error('Internal Server Error: ', {
          cause: result.error,
        })
        if (import.meta.client) {
          nuxtApp.$sentry.captureException(capturedError)
        }
      } else {
        alert.value = {
          type: ALERT_TYPE.ERROR,
          message: nuxtApp.$t('DefaultError'),
        }
        const capturedError = new Error('Unknown Error: ', {
          cause: result.error,
        })
        if (import.meta.client) {
          nuxtApp.$sentry.captureException(capturedError)
        }
      }
      loading.value = false
      return
    }

    dataLayer.basic.trackUserLogin()
  } else {
    ssoProvider.value = ssoProviderInfo
  }

  loading.value = false
}

const ssoProvider = ref<SSOProvider | null>()

const wayfUrl = computed(() => {
  const url = new URL(`${window.location.origin}/api/auth/sign-in-wayf`)
  url.searchParams.set(
    'returnurl',
    window.location.origin + window.location.pathname
  )
  return url.toString()
})

const ssoUrl = computed(() => {
  if (import.meta.server || !ssoProvider.value) return ''

  const isIdentity = ssoProvider.value.providerName === 'IdentityEntraId'

  const url = new URL(
    isIdentity
      ? `${window.location.origin}/api/auth/sign-in-sso`
      : ssoProvider.value.providerUrl
  )
  url.searchParams.set(
    'returnurl',
    window.location.origin + window.location.pathname
  )
  return url.toString()
})
const checkEmail = async () => {
  if (props.email) {
    const { err, data } = await nuxtApp.$api.user.isPasswordSet(props.email)
    if (err) {
      console.error('Error in isPasswordSet:', err.cause)
    } else if (data !== undefined) {
      isPasswordSet.value = data
    }
    // Single Sign-On checks
    if (config.public.site.allowSingleSignOn) {
      ssoProvider.value = await nuxtApp.$api.user.getSsoProviderByEmail(
        props.email
      )
    } else {
      ssoProvider.value = null
    }
  }
}
</script>
